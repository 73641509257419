<template>
  <div>
    <div class="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-">
      <form class="lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md" v-on:submit.prevent="login">
        <h1 class="lg:text-2xl text-xl font-semibold mb-6">Đăng nhập</h1>
        <span class="mr-1">Bạn chưa có tài khoản?
           <router-link :to="{name: 'Register'}" class="text-blue-800">Đăng ký</router-link>
        </span>
        <div :class="{'bg-green-500': status === 'success', 'bg-red-500': status === 'error'}"
             class="border p-4 relative rounded-md uk-alert" v-if="message">
          <p class="text-white">{{ message }}</p>
        </div>
        <div v-if="code === 'user_not_active'">
          <button type="button" class="bg-green-600 font-semibold p-2 rounded-md text-center text-white w-full"
                  v-on:click="sendActivationCode">Kích hoạt ngay
          </button>
        </div>
        <div>
          <label class="mb-0" for="identity">Tên đăng nhập</label>
          <input v-model="identity" id="identity" type="text" placeholder="Username"
                 class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full">
        </div>
        <div>
          <label class="mb-0" for="password">Mật khẩu</label>
          <input v-model="password" id="password" type="password" placeholder="******"
                 class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full">
        </div>
        <div class="d-lg-flex justify-content-between align-items-center mb-4">
          <div>
            <router-link :to="{name: 'ForgotPassword'}" class="text-blue-800 ms-1">
              Quên mật khẩu?
            </router-link>
          </div>
        </div>
        <div>
          <button type="submit" class="bg-green-600 font-semibold p-2 mt-5 rounded-md text-center text-white w-full">
            Đăng nhập
          </button>
        </div>
        <div class="w-full">
          <div id="g_id_onload"
              data-client_id="454137046742-racuvmqlqadldcummhfcg1eerknrumq6.apps.googleusercontent.com"
              data-context="signin"
              data-ux_mode="popup"
              data-login_uri="https://bcdcnt.net"
              data-callback="onSignIn"
              data-auto_prompt="false">
          </div>

          <div class="g_id_signin"
              data-type="standard"
              data-shape="rectangular"
              data-theme="outline"
              data-text="signin_with"
              data-size="large"
              data-locale="vi"
              data-logo_alignment="left">
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {saveToken} from "@/core/services/jwt.service";
import {changePageTitle} from "../core/services/utils.service";

export default {
  name: 'Login',
  data() {
    return {
      identity: "",
      password: "",
      message: "",
      code: "",
      status: "error"
    }
  },
  methods: {
    login() {
      this.message = "";

      let query = `mutation($identity: String!, $password: String!) {
        login(identity: $identity, password: $password) {
          access_token
          message
          code
        }
      }`;

      ApiService.graphql(query, {identity: this.identity, password: this.password})
          .then(({data}) => {
            if (data.data && data.data.login) {
              if (data.data.login.access_token) {
                saveToken(data.data.login.access_token);
                if (this.$route.query.next) {
                  window.location = this.$route.query.next;
                } else {
                  window.location = "/";
                }
              } else {
                this.message = data.data.login.message;
                this.code = data.data.login.code;
                this.status = "error";
              }
            } else {
              this.message = data.data.errors[0].message;
              this.status = "error";
            }
          })
          .catch((response) => {
            this.message = response.message;
            this.status = "error";
          });
    },
    sendActivationCode() {
      this.status = "";

      let query = `mutation($identity: String!) {
        sendActivationCode(identity: $identity)
      }`;

      ApiService.graphql(query, {identity: this.identity})
          .then(({data}) => {
            if (data.data && data.data.sendActivationCode) {
              this.message = "Link kích hoạt đã được gửi tới email của bạn. Vui lòng kiểm tra hòm thư của bạn";
              this.status = "success";
              this.code = "";
            } else {
              this.message = data.data.errors[0].message;
              this.status = "error";
            }
          })
          .catch((response) => {
            this.message = response.message;
            this.status = "error";
          });
    }
  },
  mounted() {
    if (this.$route.query.ref && this.$route.query.ref === "register") {
      this.message = "Bạn đã đăng ký thành công. Vui lòng kích hoạt tài khoản trước khi đăng nhập (check email)";
      this.status = "success";
    } else if (this.$route.query.ref && this.$route.query.ref === "validate") {
      this.message = "Xin chúc mừng, bạn đã xác thực thành công. Vui lòng đăng nhập";
      this.status = "success";
    } else if (this.$route.query.ref && this.$route.query.ref === "changePassword") {
      this.message = "Xin chúc mừng, bạn đã đổi mật khẩu thành công. Vui lòng đăng nhập lại";
      this.status = "success";
    }

    changePageTitle("Đăng nhập")
  }
}
</script>
